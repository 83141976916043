<template>
    <div class="main">
        <div class="header">
            <div class="header-div" style="flex: 1" @click="goBack">
                <img :src="require('@/assets/退回.png')"  style="width: 20px ;height: 20px"/>
            </div>
            <div class="header-div" style="flex: 5">剧情简介</div>
            <div class="header-div" style="flex: 1"></div>
        </div>
        <div style="margin-top: 40px"></div>
        <div>
            <el-image :src=teleplay.img style="object-fit: cover; width: 100%; height: 550px;"></el-image>


            <div style="margin: 10px;">
                <!--            <div style="display: flex; align-items: center; justify-content: flex-start;">-->
                <!--                <div style="width: 4px;height: 15px;border-radius: 10px;margin-right: 6px;background-color: #2979ff;"></div>-->
                <!--                <span style="color: #000000;font-size: 16px;font-weight:normal;font-family:微软雅黑;">标题</span>-->
                <!--            </div>-->
                <!--            <div style="margin: 10px 0px">{{teleplay.title}}</div>-->
                <!--            <div style="display: flex; align-items: center; justify-content: flex-start;">-->
                <!--                <div style="width: 4px;height: 15px;border-radius: 10px;margin-right: 6px;background-color: #2979ff;"></div>-->
                <!--                <span style="color: #000000;font-size: 16px;font-weight:normal;font-family:微软雅黑;">剧情简介</span>-->
                <!--            </div>-->
                <div style="margin: 10px 0px;font-size: 12px">{{teleplay.introduction}}</div>
            </div>

        </div>
        <div style="height: 80px"></div>

        <div class="bottomdiv" @click="startZoomAnimation">
            <el-button type="primary"  @click="saveVote()" class="button-gradient">推 广</el-button>
        </div>




        <el-dialog
                title="评论"
                :visible.sync="dialogVisible"
                :before-close="handleClose"
                width="90%">
            <div>{{comment}}</div>
            <div>
                <el-button type="text" style="margin-top: 20px" @click="commentChange">换一条评论</el-button>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveVote()">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 用于显示动画的层 -->
        <div class="zoom-overlay">
            <img
                    v-for="anim in animationList"
                    :key="anim.id"
                    v-show="anim.isRunning"
                    class="zoomed-icon"
                    :src="require('@/assets/爱心.png')"
                    :style="{ top: `${anim.top}px`, left: `${anim.left}px`, opacity: anim.opacity, transform: anim.transform }"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "Details",
        data() {
            return {
                id: '',
                item: {},
                member: {},
                teleplay: {},
                dialogVisible: false,
                drawer: false,
                isZooming: false, // 控制动画层的显示
                animationList: [], // 用于存储所有动画的状态
                comment:'',
                commentList: [
                    "好看好看",
                    "还想看",
                    "太好看了",
                    "推荐推荐",
                ],
                msgList: [
                    "爱奇艺投票成功",
                    "快手投票成功",
                    "抖音投票成功",
                    "腾讯视频投票成功",
                    "优酷视频投票成功",
                    "哔哩哔哩投票成功",
                    "西瓜视频投票成功",
                    "芒果TV投票成功",
                    "华为视频投票成功",
                    "搜狐视频投票成功",
                    "咪咕视频投票成功",
                    "小米视频投票成功"
                ],
            }
        },
        created() {
            let query = this.$route.query;
            this.id = query.id;
            // this.id = location.search.split('=')[1]
            this.load()
        },
        methods: {
            load() {
                this.request.get("/app/teleplay/queryById/" + this.id).then(res => {
                    if (res.code === '200') {
                        res.data.animation = Math.random() * 5 + 3
                        this.teleplay = res.data;
                    }
                });
                let membertoken = localStorage.getItem("membertoken")
                if (membertoken) {
                    this.request.get("/app/member/getMember").then(res => {
                        this.member = res.data
                    })
                }

            },

            //推广
            async saveVote() {
                // this.teleplay.animation = this.teleplay.animation - 1
                // if (this.teleplay.animation > 0) {
                //     return;
                // }
                if (!this.member ||!this.member.id) {
                    this.$router.push("/uploadPage")
                    return;
                }
                if (this.member.levelInfo === 'B' || this.member.levelInfo === 'C') {
                    if (!this.comment) {
                        this.commentChange()
                        this.dialogVisible = true
                        return;
                    }
                }
                var form = {
                    tid: this.teleplay.id,
                    comment: this.comment
                }
                const response = await this.request.post("/app/memberVote/saveVote", form);
                if (response.code === '200') {
                    this.teleplay.animation = Math.random() * 5 + 3
                    this.$message.success("提交成功");
                } else {
                    return;
                }
                this.handleClose();

                var count = (Math.random() * 5) + 3;
                console.log(count);

                function delayMessage() {
                    if (count > 0) {
                        // 确保this指向正确的上下文
                        const context = this; // 保存当前的this上下文
                        setTimeout(function () {
                            context.$message.success(context.getmsg());
                            count--; // 递减count
                            delayMessage.call(context); // 递归调用，继续循环
                        }, Math.random() * 1000 + 500); // 1-2秒随机延迟
                    }
                }

                delayMessage.call(this); // 启动递归延迟消息函数
            },
            handleClose() {
                this.dialogVisible = false
                this.comment = ''
            },
            commentChange() {
                const randomIndex = Math.floor(Math.random() * this.commentList.length);
                this.comment = this.commentList[randomIndex];
            },

            getmsg() {
                const randomIndex = Math.floor(Math.random() * this.msgList.length);
                return this.msgList[randomIndex];
            },

            //点赞特效
            startZoomAnimation(event) {
                // 阻止事件冒泡
                // event.stopPropagation();

                // 根据当前窗口的滚动位置调整动画的top和left值
                const offsetX = window.pageXOffset || document.documentElement.scrollLeft;
                const offsetY = window.pageYOffset || document.documentElement.scrollTop;
                const x = event.pageX - offsetX - 30;
                const y = event.pageY - offsetY - 30;

                // 计算点击位置
                // const x = event.pageX - 30;
                // const y = event.pageY - 30;

                // 创建新的动画对象并添加到列表
                const newAnimation = {
                    id: Date.now(), // 使用时间戳作为唯一标识
                    top: y,
                    left: x,
                    opacity: 1,
                    transform: 'translateY(0)',
                    isRunning: true,
                };
                this.animationList.push(newAnimation);

                // 更新动画状态以触发CSS过渡
                setTimeout(() => {
                    this.animationList.forEach(anim => {
                        if (anim.isRunning) {
                            anim.transform = 'translateY(-50px)';
                            anim.opacity = 0;
                        }
                    });
                }, 100);

                // 监听动画结束
                setTimeout(() => {
                    this.animationList = this.animationList.filter(anim => anim.isRunning);
                }, 2000); // 假设动画时间为1秒，这里设置为1100ms以确保动画完成
            },
            goBack() {
                window.history.back();
            }

        }
    }
</script>
<style scoped>
    * {
        font-family: 微软雅黑;
        font-size: 14px;
    }

    .plan_list_text {
        overflow: hidden;
        word-break: break-all; /* break-all(允许在单词内换行。) */
        text-overflow: ellipsis; /* 超出部分省略号 */
        display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
        -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
        -webkit-line-clamp: 1; /** 显示的行数 **/
    }

    .header {
        position: fixed;
        top: 0;
        z-index: 1000; /* 确保标题栏位于其他内容之上 */
        background-color: #000;
        color: #fff;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
    }
    .header-div{
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .zoom-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none; /* 防止叠加层捕获点击事件 */
    }

    /* 添加动画的关键帧 */
    @keyframes zoomAndRotateOut {
        0% {
            transform: translateY(0) rotate(0deg);
            opacity: 1;
        }
        100% {
            transform: translateY(-80px) rotate(-10deg); /* 向上移动50px并旋转-45度 */
            opacity: 0;
        }
    }

    .zoomed-icon {
        position: absolute;
        transition: opacity 1s, transform 1s;
        width: 60px;
        height: 60px;

        animation: zoomAndRotateOut 1s ease-out forwards; /* 应用关键帧动画 */
    }


    .bottomdiv{
        width: 100%;
        position: fixed; /* 固定定位 */
        bottom: 0px; /* 底部固定 */
        left: 50%; /* 向左移动50%，然后使用margin-left将其向右移动回一半的宽度 */
        transform: translateX(-50%); /* 水平居中 */
        /* 如果你不想使用transform，可以使用margin-left: -宽度/2; 来居中 */
    }
    .button-gradient {
        width: 100%;
        background-image: linear-gradient(to bottom, #885bf5, #e681d8);
        color: white; /* 文字颜色，根据你的渐变颜色调整 */
        border: none; /* 无边框 */
        padding: 10px 20px; /* 内边距和外边距 */
        border-radius: 5px; /* 边框圆角 */
        font-size: 16px; /* 字体大小 */
        cursor: pointer; /* 鼠标悬停时显示手形图标 */
        transition: background-image 0.3s ease; /* 平滑过渡效果 */
    }
</style>


